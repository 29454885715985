import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'

export enum IntegrationSystems {
  ActiveCampaign = 'ActiveCampaign',
  API = 'API',
  Deskera = 'Deskera',
  Fonteva = 'Fonteva',
  GrowthZone = 'GrowthZone',
  HigherLogic = 'HigherLogic',
  HigherLogicMagnetMail = 'HigherLogicMagnetMail',
  Hubspot = 'HubSpot',
  Imis = 'Imis',
  Impexium = 'Impexium',
  Informz = 'Informz',
  Infusionsoft = 'Infusionsoft',
  Instagram = 'Instagram',
  Klaviyo = 'Klaviyo',
  LinkedIn = 'LinkedIn',
  Mailchimp = 'Mailchimp',
  Nimble = 'Nimble',
  Pabbly = 'Pabbly',
  Pipedream = 'Pipedream',
  Pipedrive = 'Pipedrive',
  PocketRss = 'PocketRss',
  Replug = 'Replug',
  Salesflare = 'Salesflare',
  Salesforce = 'Salesforce',
  SendFox = 'SendFox',
  Shopify = 'Shopify',
  Stripe = 'Stripe',
  Switchy = 'Switchy',
  SyncSpider = 'SyncSpider',
  WordPress = 'WordPress',
  YourMembership = 'YourMembership',
  Zapier = 'Zapier',
  Zoho = 'Zoho',
  Integrately = 'Integrately',
  ConstantContact = 'ConstantContact',
  SalesforceMarketingCloud = 'SalesforceMarketingCloud',
  Zoom = 'Zoom',
}

export enum IntegrationSections {
  PLUS = 'Plus',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
}

export const RasaApiKeySystems = [
  IntegrationSystems.Pabbly.toString(),
  IntegrationSystems.Pipedream.toString(),
  IntegrationSystems.SyncSpider.toString(),
  IntegrationSystems.Zapier.toString(),
  IntegrationSystems.Integrately.toString(),
  IntegrationSystems.API.toString(),
]

export interface CommunitySystem {
  available: boolean,
  connected: boolean,
  display_name: string,
  is_active: boolean,
  integration_types: string,
  id: number,
  community_system_id: number,
  name: string,
  status: string,
  community_identifier: string,
  systemMetadata?: any,
  system_api_attribute?: any,
}

export const getCommunityIntegrations = (activeCommunity: any, person: any): Promise<CommunitySystem[]> => {
  const url: string = AjaxWrapper.getServerUrl() + `/integrations/communityIntegrations/${activeCommunity.communityId}`
  return AjaxWrapper.ajax(url, HttpMethod.GET, null)
    .then((response) => {
      const allIntegrations: CommunitySystem[] = (response || []).filter((i: CommunitySystem) => {
        const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
        const hasInstagramFeatureAccess = avlFeatures.indexOf(BillingPlanDetailCode.INSTAGRAM_INTEGRATION) > -1
        const hasZoomFeatureAccess = avlFeatures.indexOf(BillingPlanDetailCode.ZOOM_INTEGRATION) > -1
        const hasCustomIntegrationApiKeyFeatureAccess = avlFeatures.indexOf(BillingPlanDetailCode.API_INTEGRATION_KEY) > -1
        if (i.name === IntegrationSystems.Instagram && !hasInstagramFeatureAccess) {
          return false
        }
        if (i.name === IntegrationSystems.Zoom && !hasZoomFeatureAccess) {
          return false
        }
        if (i.name === IntegrationSystems.API && !hasCustomIntegrationApiKeyFeatureAccess) {
          return false
        }
        return true
      })
      return allIntegrations
    })
}
