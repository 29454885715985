import { format, parseISO, set } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { DEFAULT_TIMEZONE } from './constants'

const DATE_FORMAT = 'yyyy-MM-dd'

export const formatDate = (date: string) => {
  const options: any = {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  }
  return new Date(date).toLocaleDateString('en-US', options) +
    ' at ' + new Date(date).toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'})
}

export const subscribersFormatDate = (date: string, timeZone?: string) => {
  if (!date) {
    return '-'
  } else {
    const options1: any = {
      day: 'numeric',
      month: 'short',
      weekday: 'short',
      year: 'numeric',
    }
    const options2 = {...options1, hour: 'numeric', minute: 'numeric'}

    // Only globally cross-browser date format is YYYY-MM-DDTHH:MM:SSZ
    // Firefox doesn't accept YYYY-MM-DD HH:MM:SS UTC, but Chrome ddoes
    const utcDate = new Date(date.replace(' ', 'T') + 'Z')
    if (isNaN(utcDate.getTime())) {
      const result = ((new Date(date)).toLocaleDateString('en-US', (date.length >= 10 ? options2 : options1)))
      return result === 'Invalid Date' ? '-' : result
    } else {
      const formattedTz = timeZone ? validateTz(timeZone) : 'America/Chicago';
      const zonedDate = utcToZonedTime(utcDate, formattedTz)

      const result = (zonedDate).toLocaleDateString('en-US', (date.length >= 10 ? options2 : options1))
      return result === 'Invalid Date' ? '-' : result
    }
  }
}

export const scheduleSformatDate = (dateString: string, timezone: string, displayTimezone: boolean = true ): string => {
  const options: any = {
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    month: 'long',
    timeZone: validateTz(timezone),
    weekday: 'long',
    year: 'numeric',
  }
  const formattedDate = new Date(dateString).toLocaleString('en-US', options)
  return displayTimezone ? `${formattedDate} (${timezone})` : formattedDate
}

export const formatDateForES = (d: Date) => {
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

export const formatDateTimeForLambda = (d: Date) => {
  return format(utcToZonedTime(d, 'UTC'), 'yyyy-MM-dd HH:mm:ss')
}

export const dateToString = (date: Date): string => {
  return date.toISOString().slice(0, 10)
}

export const parseDate = (date: string) => {
  const options: any = {
    day: 'numeric',
    hours: 'numeric',
    minutes: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  }
  return  new Date(date).toLocaleDateString('en-US', options)
}

const usTimeZonesMapToWorldTZ = {
  'US/Alaska': 'America/Anchorage',
  'US/Aleutian': 'America/Adak',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/East-Indiana': 'America/Indiana/Indianapolis',
  'US/Eastern': 'America/New_York',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Indiana-Starke': 'America/Indiana/Knox',
  'US/Michigan': 'America/Detroit',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Samoa': 'Pacific/Pago_Pago',
}

export const validateTz = (tz) => Object.keys(usTimeZonesMapToWorldTZ).includes(tz) ? usTimeZonesMapToWorldTZ[tz] : tz

export const strfFormatDate = (date: Date, dateFormat: string) => {
  try {
    dateFormat =  dateFormat.replace('%a', 'iii')
          .replace('%A', 'iiii')
          .replace('%a', 'iii')
          .replace('%B', 'MMMM')
          .replace('%b', 'MMM')
          .replace('%m', 'MM')
          .replace('%d', 'dd')
          .replace('%Y', 'yyy')
          .replace('%H', 'HH')
          .replace('%I', 'hh')
          .replace('%M', 'mm')
          .replace('%S', 'ss')
          .replace('%p', 'a')
    if (dateFormat !== "No Date") {
      return format(date, dateFormat)
    } else {
      return ""
    }
  }
  catch (error) {
    return ""
  }
}

export const getEventDate = (event: any, event_date_format: string) => {
  try {
    const options = JSON.parse(event.options)
    if (options.start_time) {
      return strfFormatDate(new Date(options.start_time), event_date_format)
    }
  } catch (e) {
    // Don't care if this fails
  }
  return ""
}

export const startOfDay = (localeDate: Date, timezone: string): Date => {
  if (localeDate) {
    const localeDatePart = format(localeDate, DATE_FORMAT)
    const localeStartOfDay = `${localeDatePart}T00:00:00`
    const utcStartOfDay = zonedTimeToUtc(localeStartOfDay, validateTz(timezone))
    return utcStartOfDay
  }
  return localeDate
}

export const endOfDay = (localeDate: Date, timezone: string): Date => {
  if (localeDate) {
    const localeDatePart = format(localeDate, DATE_FORMAT)
    const localeEndOfDay = `${localeDatePart}T23:59:59`
    const utcEndOfDay = zonedTimeToUtc(localeEndOfDay, validateTz(timezone))
    return utcEndOfDay
  }
  return localeDate
}

export const resetToStartOfDay = (dbDate: string): Date => {
  if (dbDate) {
    const parsedISO = parseISO(dbDate)
    return set(parsedISO, {hours: 0, minutes: 0, seconds: 0})
  }
  return new Date()
}

export const resetToStartOfDayZonedToIso = (dbDate: string, timeZone): string => {
  if (dbDate) {
    const parsedISO = parseISO(dbDate)
    const formattedTz = timeZone ? validateTz(timeZone) : 'America/Chicago'
    const zonedDate = utcToZonedTime(parsedISO, formattedTz)
    return zonedDate.toISOString()
  }
  return new Date().toISOString()
}

export const resetToStartOfDayZoned = (dbDate: string, timeZone): string => {
  if (dbDate) {
    const parsedISO = parseISO(dbDate)
    const formattedTz = timeZone ? validateTz(timeZone) : 'America/Chicago'
    const zonedDate = utcToZonedTime(parsedISO, formattedTz)
    return format(new Date(zonedDate), 'yyyy-MM-dd')
  }
  return new Date().toLocaleDateString()
}

export const formatSendDate = (dateString: string, timezone: string): string => {
  const validatedTimezone = validateTz(timezone)
  const dateOptions: any = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  }
  const timeOptions: any = {
    hour: 'numeric',
    minute: '2-digit',
  }
  if (validatedTimezone) {
    dateOptions.timeZone = validatedTimezone
    timeOptions.timeZone = validatedTimezone
  }
  const date: Date = new Date(dateString)
  const dateFormatted: string = date.toLocaleDateString('en-US', dateOptions)
  const timeFormatted: string = date.toLocaleTimeString('en-US', timeOptions)
  const dateTimeFormatted: string = `${dateFormatted} at ${timeFormatted}`
  return validatedTimezone
    ? `${dateTimeFormatted} (${validatedTimezone})`
    : dateTimeFormatted
}

export const getCommunityTimezonedCurrentTime = (timezone: string): string => {
  const currentBrowserTime = new Date()
  const communityTimezonedCurrentTime = utcToZonedTime(currentBrowserTime, timezone || DEFAULT_TIMEZONE)
  const hours = communityTimezonedCurrentTime.getHours()
  const minutes = communityTimezonedCurrentTime.getMinutes()
  return `${hours}:${minutes}`
}

export const zohoSubscriptionTime = (dbDate: string): string => {
    const parsedISO = parseISO(dbDate)
    return  utcToZonedTime(parsedISO, 'UTC').toLocaleDateString()
}