/** Enum that matches the values in the subscription_type table */
export enum SubscriptionTypes {
  lead = 4,
  normal = 1,
  preferred = 5,
}

/** Enum that matches the values in the format_type table */
export enum FormatTypes {
  event = 4,
  podcast = 2,
  text = 1,
  video = 3,
}

export enum ContentFilterTypes {
  blacklist = 'blacklist',
  whitelist = 'whitelist',
}

export enum SourceTypes {
  facebook = 'Facebook',
  googleNews = 'Google News',
  instagram = 'Instagram',
  linkedIn = 'LinkedIn',
  native = 'Manual Entry',
  pocket = 'Pocket',
  podcast = 'Podcast',
  rss = 'RSS Feed',
  scraper = 'Scraper',
  topic = 'Topic',
  twitter = 'Twitter',
  twitterList = 'Twitter List',
  upcontent = 'UpContent',
  youtube = 'YouTube',
  zoom = 'Zoom',
}

export enum EmailTemplates {
  bigAndBold = 'self-service-big-bold.html.jinja',
  default = 'self-service-default.html.jinja',
  newspaper = 'self-service-newspaper.html.jinja',
  insights = 'self-service-insights.html.jinja',
  oldBigAndBold = 'self-service-big-bold.jinja',
  oldDefault = 'self-service-default.jinja',
  oldNewspaper = 'self-service-newspaper.jinja',
  stripo = 'stripo_template'
}

export enum DomainStatus {
  valid = 'valid',
  invalid = 'invalid',
  pending = 'pending',
  deleted = 'deleted',
}

export enum EmailVerificationStatus {
  confirmed = 'confirmed',
  pending = 'pending',
  unknown = 'unknown',
  failed = 'failed',
}

export enum RasaEmailFooterImages {
  large = 'https://image-api.rasa.io/image/hosted-images-newsbrief/path/rasa/rasa_io-logo-white_outline-words.png?w=200&h=80',
}

export enum EmailAttributes {
  brandFooterImageUrl = 'brand_footer_image_url',
  brandHeaderImageUrl = 'brand_header_image_url',
  brandHeaderUrl = 'brand_header_url',
  brandHeaderAltText = 'brand_header_alt_text',
  brandFooterAltText = 'brand_footer_alt_text',
  brandFooterUrl = 'brand_footer_url',
  categoryTypeIdForSubject = 'category_type_id_for_subject',
  companyCityStateZip = 'company_city_state_zip',
  companyName = 'company_name',
  companyStreetAddress = 'company_street_address',
  companyWebsite = 'company_website',
  contactUsUrl = 'contact_url',
  contactUsUrlText = 'contact_url_text',
  descriptionCapText = 'description_cap_text',
  descriptionCharacterLimit = 'description_character_limit',
  descriptionLength = 'description_length',
  emailBodyBackgroundColor = 'email_body_background_color',
  emailSubject = 'email_subject',
  emailTemplate = 'email_template',
  eventDateFormat = 'event_date_format',
  facebookName = 'facebook_icon_link',
  feedbackUrlText = 'feedback_url_text',
  fontColor = 'font_color',
  fontFamily = 'font_family',
  fontSize = 'font_size',
  fontWeight = 'font_weight',
  footerCopyRightText = 'footer_copyright_text',
  footerFontColor = 'footer_font_color',
  footerLogoSize = 'footer_logo_size',
  footerTextFieldOne = 'footer_text_field_one',
  footerTextFieldTwo = 'footer_text_field_two',
  fromEmail = 'from_address',
  fromName = 'from_address_name',
  articleAltText = 'image_alt_text',
  headerDateFormat = 'header_date_format',
  subjectDateFormat = 'subject_date_format',
  hideHeaderBackground = 'hide_header_background',
  htmlEditing = 'html_editing',
  instagramName = 'instagram_icon_link',
  leadText = 'lead_text',
  leadTextColor = 'lead_text_color',
  leadTextFontSize = 'lead_text_font_size',
  leadTextFontWeight = 'lead_text_font_weight',
  leadTextFontStyle = 'lead_text_font_style',
  leadTextHtmlEditing = 'lead_text_html_editing',
  leadTextBorderColor = 'lead_text_border_color',
  leadTextBorderWidth = 'lead_text_border_width',
  leadTextIsActive = 'lead_text_is_active',
  leadText2 = 'lead_text_2',
  leadText2Color = 'lead_text_2_color',
  leadText2FontSize = 'lead_text_2_font_size',
  leadText2FontWeight = 'lead_text_2_font_weight',
  leadText2FontStyle = 'lead_text_2_font_style',
  leadText2HtmlEditing = 'lead_text_2_html_editing',
  leadText2BorderColor = 'lead_text_2_border_color',
  leadText2BorderWidth = 'lead_text_2_border_width',
  leadText2IsActive = 'lead_text_2_is_active',
  learnMoreUrl = 'learn_more_url',
  learnMoreUrlText = 'learn_more_url_text',
  linkedInName = 'linkedin_icon_link',
  logoSize = 'logo_size',
  maxArticles = 'newsbrief_count',
  maxEvents = 'max_events_count',
  maxArticlesPerSource = 'max_per_source',
  maxLeadArticles = 'max_lead_count',
  maxSponsoredArticles = 'max_sponsored_articles',
  minArticles = 'minimum_newsbrief_count',
  newsletterFontFamily = 'newsletter_font_family',
  previewText = 'preview_text',
  primaryColor = 'primary_color',
  readMoreText = 'read_more_text',
  restrictNewsletterImages = 'restrict_newsletter_images',
  secondaryColor = 'secondary_color',
  showNewsletterImages = 'show_newsletter_images',
  showRasaLogo = 'show_rasa_logo',
  showSectionNameInHeader = 'show_section_name_in_header',
  showSubscribeUrlInHeader = 'show_subscribe_url_in_header',
  showUnsubscribeUrlInHeader = 'show_unsubscribe_url_in_header',
  staticImages = 'static_images',
  staticTexts = 'static_texts',
  subscribeUrl = 'subscribe_url',
  subscribeUrlText = 'subscribe_url_text',
  titleCapText = 'title_cap_text',
  titleFontColor = 'title_font_color',
  twitterName = 'twitter_icon_link',
  unsubscribeUrl = 'unsubscribe_url',
  unsubscribeUrlText = 'unsubscribe_url_text',
  viewInBrowser = 'view_in_browser',
  whitelabelLogoUrl = 'whitelabel_logo_url',
  youtubeName = 'youtube_icon_link',
  communityPartnerCode = 'community_partner_code',
  threadsName = 'threads_icon_link',
  stripoDefaultTemplateName = 'stripo_default_template_name'
}

export enum ScheduleFrequency {
  biweekly = 'biweekly',
  daily = 'daily',
  manually = 'manually',
  monthly = 'monthly',
  none = 'none',
  weekly = 'weekly',
}

export interface LeadTextAttributes {
  borderColor: string,
  borderWidth: string,
  color: string,
  fontSize: string,
  fontStyle: string,
  fontWeight: string,
  isHtmlEdited: string,
  isActive: string,
  text: string,
}

export const leadTextAttributes: LeadTextAttributes = {
  borderColor: 'border-color',
  borderWidth: 'border-width',
  color: 'color',
  fontSize: 'font-size',
  fontStyle: 'font-style',
  fontWeight: 'font-weight',
  isActive: 'is_active',
  isHtmlEdited: 'is_edited',
  text: 'description',
}

export interface LeadTextDefaults {
  borderColor: string,
  borderWidth: string,
  color: string,
  fontSize: string,
  fontStyle: string,
  fontWeight: string,
  isHtmlEdited: boolean,
  isActive: boolean,
  text: string,
}

export const leadTextDefaults: LeadTextDefaults = {
  borderColor: '#000000',
  borderWidth: '0px',
  color: '#000000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  isActive: null,
  isHtmlEdited: false,
  text: null,
}

export const DEFAULT_TIMEZONE = 'US/Central'
export const DATE_FORMAT = 'ccc, MMM dd, yyyy'
export const DATE_TIME_FORMAT = 'ccc, MMM dd, yyyy HH:MM'
export const EVENT_DATE_TIME_FORMAT = 'iiii dd, yyy, hh:mm a'
export const DATE_FORMAT_MOBILE = 'MMM dd'
export const CAPPED = 'AccountLimitFailure'

export const DASHBOARD_ACCOUNT_PAGE = '/settings/account'
export const DASHBOARD = 'dashboard'
export const UNLIMITED_VALUE = 1000000
export const ADD = 'add'
export const IMAGE = 'image'
export const BANNER_IMAGE_MODULE = 'image'
export const SQUARE_IMAGE_MODULE = 'twoColumnImage'
export const LEFT_SQUARE = 'leftSquare'
export const RIGHT_SQUARE = 'rightSquare'
export const TEXT = 'text'
export const ARTICLE = 'article'
export const LEAD_TEXT_TYPE_PREFIX = 'Text'
export const SPONSORED = 'sponsored'

export enum ScheduledContentTypes {
  article = 'scheduledArticle',
  image = 'scheduledImage',
  sponsored = 'sponsoredArticle',
  text = 'scheduledText',
}

export enum PlanUpdateTypes {
  UPGRADE = 'plan-upgrade',
  DOWNGRADE = 'plan-downgrade',
  CANCELLATION = 'plan-cancellation',
  NEW_PAID_SUBSCRIPTION = 'new-paid-subscription',
  CONTACT_UPGRADE = 'contact-upgrade',
  CONTACT_DOWNGRADE = 'contact-downgrade',
}

export const NO_BOOST_LEVEL = 0
export const NORMAL_BOOSTED_BOOST_LEVEL = 1
export const SCHEDULED_ARTICLES_BOOST_LEVEL = 5
export const SUPER_BOOSTED_BOOST_LEVEL = 10

export const DEFAULT_MAX_SPONSORED_CONTENT = 4
export const DEFAULT_MAX_LAYOUT_MODULES = 0

export enum UNSUBSCRIBE_REASON {
  ADMIN_UI = 'AdminUI',
  AI_UNSUBSCRIBE = 'AIUnsubscribe',
  API = 'API',
  BOUNCE = 'Bounce',
  CSV = 'CSV',
  DROPPED = 'Dropped',
  ONE_CLICK_UNSUBSCRIBE = 'OneClickUnsubscribe',
  SOFT_BOUNCE = 'SoftBounce',
  SPAM_REPORT = 'SpamReport',
  UI = 'UI',
  UNSUBSCRIBED = 'Unsubscribed',
  OTHERS = 'Others'
}

export enum DEFAULT_COMMUNITY_PARTNER_CODES {
  ALL = "All",
  NO_CODE = "No Code",
}

export const TEMPLATE_MODULES = 'template_modules'
export const DUMMY_DATE = '1970-01-01 00:00:00'
